<template>
  <DialogFormWrapper>
    <template #form>
      <ClientForm
        v-if="clientFetched"
        ref="editClientForm"
        class="pt-8"
        :editing-client="client"
        @submitClientForm="updateClient"
      />
    </template>
    <template #submit>
      <v-btn
        color="primary"
        type="submit"
        class="form-action"
        :loading="isProcessing"
        @click="$refs.editClientForm.submitClientForm()"
      >
        Zapisz zmiany
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ClientForm from './Partials/ClientForm'
import { mapState, mapActions } from 'vuex'
import get from 'lodash/get'
import { Course } from '../../models'

export default {
  components: {
    ClientForm,
    DialogFormWrapper
  },
  data: () => ({
    clientFetched: false
  }),
  computed: {
    ...mapState({
      isProcessing: state => state.clients.isProcessing,
      dialog: state => state.layout.dialog,
      client: state => state.client.entity,
    })
  },
  mounted () {
    const { item, data: { idPath, isCourse } } = this.dialog
    const parsedItem = isCourse ? new Course(item) : item
    const id = get(parsedItem, idPath)

    this.getSingleClient(id)
      .then(() => { this.clientFetched = true })
  },
  beforeDestroy () {
    this.clearSingleClient()
  },
  methods: {
    ...mapActions({
      editClient: 'clients/editItem',
      closeDialog: 'layout/closeDialog',
      getSingleClient: 'client/getSingleClient',
      getMiddlemen: 'middlemen/getItems',
      clearSingleClient: 'client/clearSingleClient',
    }),
    updateClient (updatedData) {
      const params = {
        tableName: 'clients',
        params: updatedData,
        id: this.client.id
      }
      this.editClient(params)
        .then(() => {
          if (params.params.isMiddleman) this.getMiddlemen()
          this.closeDialog()
        })
    },
  }
}
</script>
